import { SliceState, Status } from "../../types/common";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { VoucherApi } from "../../api/VoucherApi";
import { RootState } from "../index";
import { executePromise } from "../../util/sliceUtil";
import {RegisterVoucherDTO} from "../../types/voucher";

export interface VoucherState extends SliceState {}

const initialState: VoucherState = {
  status: Status.IDLE,
};

export const getUserVoucherCountAsync = createAsyncThunk("voucher/getUserVoucherCount", () =>
  executePromise(VoucherApi.getUserVoucherCount())
);

export const getOrganVoucherCountAsync = createAsyncThunk("voucher/getOrganVoucherCount", () =>
  executePromise(VoucherApi.getOrganVoucherCount())
);

export const registerExternalVoucherAsync =
    createAsyncThunk("voucher/registerExternalVoucher", (dto: RegisterVoucherDTO) =>
  executePromise(VoucherApi.registerExternalVoucher(dto))
);

export const getUserVoucherCountKpassAsync = createAsyncThunk("voucher/getKpassVoucherCount", () =>
  executePromise(VoucherApi.getUserVoucherCountKpass())
);

export const getUserVoucherCountDcasAsync = createAsyncThunk("voucher/getDcasVoucherCount", () =>
  executePromise(VoucherApi.getUserVoucherCountDcas())
);

export const voucherSlice = createSlice({
  name: "voucher",
  initialState,
  reducers: {},
  extraReducers: (builder) => {},
});

export const voucherStatus = (state: RootState) => state.voucher.status;

export default voucherSlice.reducer;
