import {MainApi} from "./MainApi";
import {AffiliateCodeCommissionCheckerParam, CreateOrderParam} from "../types/order";
import {CouponCodeRequestDTO} from "../types/couponCode";

export class OrderApi {
  static baseUrl = `${MainApi.urlPrefix}/user/orders`;
  static organUrl = `${MainApi.urlPrefix}/organ-manager`;
  static couponCodeUrl = `${MainApi.urlPrefix}/coupon-code`;

  static postOrder = (orderParam: CreateOrderParam) => () => MainApi.api.post(`${OrderApi.baseUrl}`, orderParam);

  static getOrder = (orderIdx: number) => () => MainApi.api.get(`${OrderApi.baseUrl}/${orderIdx}`);
  
  static getRefundOrder = (orderIdx: number) => () => MainApi.api.get(`${OrderApi.baseUrl}/${orderIdx}/refunds`);

  // mypage 결제 내역
  static getOrders = (orderIdx: number) => () => MainApi.api.get(`${OrderApi.baseUrl}?page=${orderIdx}&size=4`);

  static getAffiliateCode = (code: string) => () =>
    MainApi.api.get(`${MainApi.urlPrefix}/affiliate-codes?code=${encodeURIComponent(code)}`);

  static getCouponCode = (body: CouponCodeRequestDTO) => () =>
    MainApi.api.post(`${OrderApi.couponCodeUrl}`, body);

  static checkAffiliateCodeCommission = (param: AffiliateCodeCommissionCheckerParam) => () =>
    MainApi.api.post(`${OrderApi.baseUrl}/check-affiliate-commission`, param);
}
