import { useLanguage } from "../../../hooks/hooks";
import { useDragEvent } from "../../../hooks/useDragEvent";
import React from "react";
import style from "../article1/banner/banner.module.scss";
import {NavigationButton} from "../../common/button/NavigationButton";
import {BannerProps} from "../article1/banner/Banner0";
import {useBannerRef} from "../article1/banner/useBannerRef";
import {BannerVO, LinkOption} from "../../../types/hooks/banner";

interface Props extends BannerProps {
  isKoreanAndNotAppPage: boolean;
  banners: BannerVO;
}
export function Banners({ wrapperRefCurrent, movePrev, moveNext, banners }: Props) {
    const { show, bannerRef } = useBannerRef({ wrapperRefCurrent });
    const { getLanguageStyle } = useLanguage();

    const { handleDragStart, handleDragEnd } = useDragEvent({
        moveRight: () => moveNext?.(),
        moveLeft: () => movePrev?.(),
    });

    const handleClickBannerImage = (bannerVO: BannerVO) => {
        window.open(banners.buttonLink, banners.linkOption === LinkOption.NEW_TAB ? "_blank" : "_self");
    };

  return (
    <>
      {banners && (
        <div className={`${style.bannerWrapper}`} ref={bannerRef}>
          <div className={style.bannerImage} draggable={true} onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
            <img
              className={`${show ? style.show : style.hide}`}
              style={{cursor:"pointer"}}
              src={banners.image.uri}
              alt={"배너 이미지"}
              draggable={"false"}
              onClick={() => handleClickBannerImage(banners)}
            />
          </div>
          <div className={`${style.textWrapper} ${getLanguageStyle(style)} ${show ? style.show : style.hide}`}>
            <p className={style.slogan}>
              {banners.description1}
              <br />
              <span className={style.strong}>{banners.description2}</span>
            </p>
            <div className={style.downloadTestButtonWrapper}>
              <NavigationButton
                buttonText={
                  // isWithAppPage ? t("section1.download_app") : t("section1.bannerArtistAndStrategist.buttonLabel")
                  banners.buttonDescription
                }
                onClickEvent={() => {
                  window.open(banners.buttonLink, banners.linkOption === LinkOption.NEW_TAB ? "_blank" : "_self");
                  // switch (true) {
                  //     case isWithAppPage:
                  //         navigate(banners.buttonLink);
                  //         break;
                  //     case isKoreanAndNotAppPage:
                  //         navigate(`/purchase-voucher`);
                  //         break;
                  //     default:
                  //         navigate(`/understanding/kpass`);
                  //         break;
                  // }
                }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
