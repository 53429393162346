import React from "react";
import { ModalContent, ModalType } from "../../types/common";
import { TermsCategory } from "../../types/terms";
import { AffiliateCodeVO } from "../../types/affiliateCode";

export interface ModalContextType {
  showModal: ModalType;
  openModal: (_: ModalType) => void;
  closeModal: () => void;
  openConfirmModal: (modalContent: ModalContent, confirmCallback: VoidFunction) => void;
  openAlertModal: (_: ModalContent | null) => void;
  openTermsModal: (_: TermsCategory) => void;
  openReviewEditModal: (_: number) => void;
  openReviewDetailModal: (_: number, isBestReview: boolean) => void;
  openAffiliateCodeModal: (_: AffiliateCodeVO) => void;
  openVoucherRegisterModal: () => void;
}

export const ModalContext = React.createContext<ModalContextType>(null!);
