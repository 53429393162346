import {ModalType} from "../../../../types/common";
import modalStyle from "../../../../assets/scss/common/modal/modal.module.scss";
import style from "./externalVoucherModal.module.scss";
import giftImage from "../../../../assets/images/mypage/voucher_gift_image.svg"
import {Modal} from "../Modal";
import {useAppDispatch, useModal, useUserAgent} from "../../../../hooks/hooks";
import {useCallback, useState} from "react";
import {CodeInput} from "./CodeInput";
import {registerExternalVoucherAsync} from "../../../../store/voucher/voucherSlice";
import {RegisterVoucherDTO} from "../../../../types/voucher";

export function ExternalVoucherRegister() {
    const {showModal, closeModal, openAlertModal} = useModal();
    const dispatch = useAppDispatch();
    const [codeValues, setCodeValues] = useState<string[]>(Array(8).fill(''));
    const [activeBox, setActiveBox] = useState<number | null>(null);
    const [errorText, setErrorText] = useState("")
    const onClickCloseBtn = () => closeModal();
    const {isMobile} = useUserAgent();
    const registerText = isMobile
        ? <>외부 채널에서 구매한<br /> 이용권 코드를 입력해 주세요.<br />(영대문자, 숫자 랜덤 코드 8자리 입력)</>
        : <>외부 채널에서 구매한 이용권 코드를 입력해 주세요.<br />(영대문자, 숫자 랜덤 코드 8자리 입력)</>;


    const onClickRegisterButton = () => {
        const data = codeValues.join('')

        const hasEmptyValue = codeValues.some(value => value === '');
        const hasForbiddenWord = codeValues.some(
            value => value === 'I' || value === 'L' || value === 'O' || value === '0'
        );

        if (hasEmptyValue) {
            setErrorText("8자리 코드를 모두 입력해 주세요.")
            return;
        }

        if (hasForbiddenWord) {
            setErrorText("입력 하신 코드를 다시 한 번 확인해 주세요.")
            return;
        }

        registerVoucher(data).then((result) => {
            if (result) {
                openAlertModal({
                    title: "이용권 코드 등록 안내",
                    desc: "이용권 코드가 정상적으로 등록 되었습니다.",
                    callback: () => {
                        closeModal()
                        window.location.reload();
                    }
                });

            }
        });
    }

    const registerVoucher = useCallback(
        async (voucherCode: string) => {
            const dto: RegisterVoucherDTO = {voucherCode}
            try {
                await dispatch(registerExternalVoucherAsync(dto)).unwrap();
                return true;

            } catch (error: any) {
                // JSON 파싱
                const parsedError = JSON.parse(error.message);

                if (parsedError.errorCode.httpCode === 400) {
                    setErrorText("이미 사용된 코드입니다.")
                }

                if (parsedError.errorCode.httpCode === 404) {
                    setErrorText("입력 하신 코드를 다시 한 번 확인해 주세요.")
                }
                console.log(error);
                return false;
            }
        },
        [dispatch]
    );

    return (
        <Modal
            showModal={showModal.valueOf() === ModalType.EXTERNAL_VOUCHER.valueOf()}
            title={"이용권 코드 등록"}
            closeModalFunction={onClickCloseBtn}
            modalBgStyle={modalStyle.mainColorBg}
            modalCloseButtonStyle={modalStyle.mainColorBg}
        >
            <div className={style.body}>
                <div className={style.contents}>
                    <img className={style.giftImage} src={giftImage} alt={"giftImage"}/>
                    <div className={style.registerArea}>
                        <span className={style.text}>
                            {registerText}
                        </span>

                        <CodeInput onSubmit={onClickRegisterButton}
                            errorText={errorText} setErrorText={setErrorText}
                            activeBox={activeBox} setActiveBox={setActiveBox}
                            codeValues={codeValues} setCodeValues={setCodeValues}
                        />

                        <div className={style.buttonsWrapper} onClick={onClickRegisterButton}>
                            <span className={style.text}>
                                등록
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}