import {MainApi} from "./MainApi";
import {RegisterVoucherDTO} from "../types/voucher";

export class VoucherApi {

  static baseUrl = `${MainApi.urlPrefix}/user/voucher`;
  static externalBaseUrl = `${MainApi.urlPrefix}/user/external-voucher`;

  static getUserVoucherCount = () => () =>
    MainApi.api.get(`${VoucherApi.baseUrl}/count`);

  static getUserVoucherCountKpass = () => () =>
    MainApi.api.get(`${VoucherApi.baseUrl}/count?type=KPASS`);

  static getUserVoucherCountDcas = () => () =>
    MainApi.api.get(`${VoucherApi.baseUrl}/count?type=DCAS`);

  static getOrganVoucherCount = () => () =>
    MainApi.api.get(`${VoucherApi.baseUrl}/count-from-organ`);

  static registerExternalVoucher = (dto: RegisterVoucherDTO) => () =>
    MainApi.api.post(`${VoucherApi.externalBaseUrl}/register`, dto);
}