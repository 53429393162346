import {useCallback} from "react";
import {getCouponCodeAsync} from "../../store/order/orderSlice";
import {useAppDispatch} from "../hooks";
import {CouponCodeDTO, CouponCodeRequestDTO} from "../../types/couponCode";
import {useErrorType} from "../useErrorType";

interface Args {
    setCodeErrorMessage: (_: string) => void;
}

export function useCouponCodeGetter({setCodeErrorMessage}:Args) {
    const dispatch = useAppDispatch();
    const { getErrorObject } = useErrorType();

    const getCouponCode = useCallback(
        async ({code, productIdx}: { code: string, productIdx: number }) => {
            try {
                const dto: CouponCodeRequestDTO = {code: code.trim(), productIdx: productIdx};
                const result: CouponCodeDTO = await dispatch(getCouponCodeAsync(dto)).unwrap();

                return result;
            } catch (e){
                console.error("getAvailableAffiliateCode occurs an error - ", e);
                const errorType = getErrorObject((e as { message: string }).message);
                const errorMessage = errorType?.data;
                setCodeErrorMessage(errorMessage)
            }
        },
        // eslint-disable-next-line
        [dispatch]
    );

    return {
        getCouponCode,
    };
}