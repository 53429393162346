import React, { useState } from "react";
import style from "../../../assets/scss/common/modal/addChildModal.module.scss";
import CloseBtn from "../../../assets/images/modal/close_btn.svg";
import RefundModal from "./RefundModal";
import { useTimeConverter } from "../../../hooks/hooks";
import {numberWithCommas} from "../../../util/commonFunctions";

interface PaymentInfoModalProps {
  order: any;
  onClose: () => void;
}

export const PaymentInfoModal: React.FC<PaymentInfoModalProps> = ({ order, onClose }) => {
  const [isRefundModalOpen, setIsRefundModalOpen] = useState(false);

  const openRefundModal = () => setIsRefundModalOpen(true);
  const closeRefundModal = () => setIsRefundModalOpen(false);
  const { timeConverter } = useTimeConverter();
  const codeDiscountAmount = Math.max(order.affiliateCodeDiscountAmount, order.couponCodeDiscountAmount);

  const getStatusTextAndClass = (status: string) => {
    switch (status) {
      case "AWAITING_PAYMENT":
        return { text: "결제대기중", className: style.awaitingPayment };
      case "AWAITING_DEPOSIT":
        return { text: "입금확인중", className: style.awaitingDeposit };
      case "PAID":
        return { text: "결제완료", className: style.paid };
      case "FAIL":
        return { text: "결제실패", className: style.fail };
      case "OUTSTANDING_PAYMENT":
        return { text: "주문취소", className: style.outstandingPayment };
      case "PARTIALLY_CANCELLED":
        return { text: "부분취소", className: style.partiallyCancelled };
      case "CANCELLED":
        return { text: "환불완료", className: style.cancelled };
      default:
        return { text: "알수없음", className: "" };
    }
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        alert("계좌번호가 클립보드에 복사되었습니다.");
      })
      .catch((err) => {
        console.error("클립보드에 복사하는 중 오류 발생:", err);
      });
  };

  // 환불 가능 여부를 확인하는 함수
  const isRefundable = () => {
    const paidDate = new Date(order.paidAt);
    const currentDate = new Date();
    const diffTime = currentDate.getTime() - paidDate.getTime();
    const diffDays = diffTime / (1000 * 60 * 60 * 24);
    return diffDays <= 7;
  };

  const { text: statusText, className: statusClass } = getStatusTextAndClass(order.status);

  return (
    <>
      <div className={style.addChildModalOverlay}>
        <div className={style.addChildInfoModal}>
          <div className={style.closeBtnContainer}>
            <img src={CloseBtn} alt="closeBtn" className={style.closeBtn} onClick={onClose} />
          </div>
          <div className={style.paymentInnerModalContainer}>
            <h3 className={style.addChildInfoTitle}>결제내역 상세보기</h3>
            <div className={style.paymentTime}>
              <span className={style.timeText}>{timeConverter.convertToLocalDate(order.createAt)}</span>
              <span className={`${style.paymentResult} ${statusClass}`}>{statusText}</span>
            </div>
            <div className={style.innerTextPaymentContainer}>
              <h3 className={style.innerTextTitle}>결제상품</h3>
              <div className={style.productContainer}>
                <div>
                  <img
                    src={order.orderImage || "https://img1.newsis.com/2023/07/12/NISI20230712_0001313626_web.jpg"}
                    className={style.productImg}
                  />
                </div>
                <div className={style.productInfoContainer}>
                  <p className={style.productName}>{order.orderTitle}</p>
                  <p className={style.productCount}>{order.orderQuantity}개</p>
                  <p className={style.productPrice}>{order.regularPriceAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원</p>
                </div>
              </div>
            </div>
            <div className={style.innerTextPaymentContainer} style={{ marginTop: "30px" }}>
              <h3 className={style.innerTextTitle}>결제정보</h3>
              <div className={style.amountContainer}>
                <div className={style.totalAmount}>
                  <span>총 상품 금액</span>
                  <span>{numberWithCommas(order.regularPriceAmount)}원</span>
                </div>
                <div className={style.discountAmount}>
                  <span>할인금액</span>
                  <span>
                    -{numberWithCommas(order.productDiscountAmount + order.couponDiscountAmount + codeDiscountAmount)}원
                  </span>
                </div>
                <div className={style.productDiscount}>
                  <span>ㄴ 상품 할인</span>
                  <span>-{numberWithCommas(order.productDiscountAmount)}원</span>
                </div>
                <div className={style.couponDiscount}>
                  <span>ㄴ 쿠폰 할인</span>
                  <span>-{numberWithCommas(order.couponDiscountAmount)}원</span>
                </div>
                <div className={style.codeDiscount}>
                  <span>ㄴ 코드 할인</span>
                  <span>-{numberWithCommas(codeDiscountAmount)}원</span>
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" width="400" height="2" viewBox="0 0 400 2" fill="none" className={style.lineImg}>
                  <path d="M0 1L400 0.999965" stroke="#898989" />
                </svg>
                <div className={style.totalPaymentAmount}>
                  <span>총 결제금액</span>
                  <span>{numberWithCommas(order.payAmount)}원</span>
                </div>
              </div>
            </div>
            {order.paymentWay === "CARD" && (
              <div className={style.innerTextPaymentContainer} style={{ marginTop: "30px" }}>
                <div className={style.paymentMethodTextContainer}>
                  <h3 className={style.innerTextTitle}>결제수단</h3>
                  <span className={style.paymentMethod}>신용카드</span>
                </div>
              </div>
            )}
            {order.paymentWay === "GOOGLE" && (
              <div className={style.innerTextPaymentContainer} style={{ marginTop: "30px" }}>
                <div className={style.paymentMethodTextContainer}>
                  <h3 className={style.innerTextTitle}>결제수단</h3>
                  <span className={style.paymentMethod}>Google Play</span>
                </div>
              </div>
            )}
            {order.paymentWay === "APPLE" && (
              <div className={style.innerTextPaymentContainer} style={{ marginTop: "30px" }}>
                <div className={style.paymentMethodTextContainer}>
                  <h3 className={style.innerTextTitle}>결제수단</h3>
                  <span className={style.paymentMethod}>App Store</span>
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" width="400" height="2" viewBox="0 0 400 2" fill="none" className={style.lineImg}>
                  <path d="M0 1H400" stroke="#C6C6C6" stroke-dasharray="2 2" />
                </svg>
                <ul className={style.paymentDescContainer}>
                  <li>App Store 환불은 Apple을 통해서만 가능합니다.</li>
                  <li>Apple 고객센터(080-333-4000)로 문의하시기 바랍니다.</li>
                </ul>
              </div>
            )}
            {order.paymentWay === "PAYPAL" && (
              <div className={style.innerTextPaymentContainer} style={{ marginTop: "30px" }}>
                <div className={style.paymentMethodTextContainer}>
                  <h3 className={style.innerTextTitle}>결제수단</h3>
                  <span className={style.paymentMethod}>PayPal</span>
                </div>
              </div>
            )}
            {order.paymentWay === "VBANK" && order.status === "OUTSTANDING_PAYMENT" && (
              <div className={style.innerTextPaymentContainer} style={{ marginTop: "30px" }}>
                <div className={style.paymentMethodTextContainer}>
                  <h3 className={style.innerTextTitle}>주문취소</h3>
                  <span className={style.paymentMethod}>가상계좌</span>
                </div>
                <div className={style.paymentMethodTextContainer}>
                  <span className={style.paymentDescription}>
                    기한내 결제금액이 입금되지 않아 자동 주문최소되었습니다.
                  </span>
                </div>
              </div>
            )}
            {order.paymentWay === "VBANK" && order.status !== "OUTSTANDING_PAYMENT" && (
              <div className={style.innerTextPaymentContainer} style={{ marginTop: "30px" }}>
                <div className={style.paymentMethodTextContainer}>
                  <h3 className={style.innerTextTitle}>결제수단</h3>
                  <span className={style.paymentMethod}>가상계좌</span>
                </div>
                {order.status !== "PAID" && (
                  <>
                    <div className={style.paymentMethodTextContainer}>
                      <span className={style.paymentDescription}>
                        주문 완료일 기준 7일 이내에
                        <br />
                        아래 계좌로 총 결제금액을 입금해주세요.
                        <br />
                        입금 확인 후 이용권 지급 예정입니다.
                      </span>
                    </div>
                    <div className={`${style.paymentMethodTextContainer} ${style.vBankTextContainer}`}>
                      <span className={style.vBankText}>{`${order.vbankName} ${order.vbankAccountNumber}`}</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                        onClick={() => copyToClipboard(order.vbankAccountNumber)}
                        style={{ cursor: "pointer" }}
                      >
                        <path
                          d="M9.69141 9.73204V8.082C9.69141 7.4869 10.1783 7 10.7734 7H16.2645C16.8596 7 17.3465 7.4869 17.3465 8.082V13.5731C17.3465 14.1682 16.8596 14.6551 16.2645 14.6551H15.1555"
                          stroke="#757575"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M14.0731 9.73242H8.582C7.98443 9.73242 7.5 10.2168 7.5 10.8144V16.3056C7.5 16.9031 7.98443 17.3875 8.582 17.3875H14.0731C14.6707 17.3875 15.1551 16.9031 15.1551 16.3056V10.8144C15.1551 10.2168 14.6707 9.73242 14.0731 9.73242Z"
                          stroke="#757575"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>

                    <svg xmlns="http://www.w3.org/2000/svg" width="400" height="2" viewBox="0 0 400 2" fill="none" className={style.lineImg}>
                      <path d="M0 1H400" stroke="#C6C6C6" stroke-dasharray="2 2" />
                    </svg>
                    <ul className={style.paymentDescContainer}>
                      <li>입금 확인 후 이용권이 지급됩니다.</li>
                      <li>주문 후 7일 이내 입금하지 않을 경우, 주문이 자동 취소됩니다.</li>
                      <li>아래 같은 경우 입금확인이 어려우니 고객센터로 문의 바랍니다.</li>
                      <li className={style.noDisc}>- 주문 금액과 입금 금액이 다른 경우</li>
                      <li className={style.noDisc}>- 주문자명과 입금자명이 다른 경우</li>
                    </ul>
                  </>
                )}
              </div>
            )}
            {order.status === "CANCELLED" && (
              <div className={style.innerTextPaymentContainer} style={{ marginTop: "30px" }}>
                <h3 className={style.innerTextTitle}>환불정보</h3>
                <div className={style.amountContainer}>
                  <div className={style.totalAmount}>
                    <span>
                      {timeConverter.convertToFormattedDate(timeConverter.convertToLocalDate(order.createAt))}{" "}
                      {order.refundPrice < order.payAmount ? "부분환불" : ""}
                    </span>
                    <span>{order?.refundPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원</span>
                  </div>

                  <svg xmlns="http://www.w3.org/2000/svg" width="400" height="2" viewBox="0 0 400 2" fill="none" className={style.lineImg}>
                    <path d="M0 1L400 0.999965" stroke="#898989" />
                  </svg>
                  <div className={style.totalPaymentAmount} style={{ color: "#E60063" }}>
                    <span>총 환불금액</span>
                    <span>{order?.refundPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원</span>
                  </div>
                </div>
              </div>
            )}
            {order.status !== "APPLE" && order.status == "PAID" && isRefundable() && (
              <button className={style.submitBtn} onClick={openRefundModal}>
                환불하기
              </button>
            )}
          </div>
        </div>
      </div>
      <RefundModal isOpen={isRefundModalOpen} onClose={closeRefundModal} />
    </>
  );
};
