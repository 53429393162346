import style from "../../../assets/scss/sub/purchase/orderAndPayment.module.scss";
import {SupportButton} from "./SupportButton";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useAvailableAffiliateCodeGetter} from "../../../hooks/order/useAvailableAffiliateCodeGetter";
import {useCouponCodeGetter} from "../../../hooks/order/useCouponCodeGetter";
import {CouponCodeDTO} from "../../../types/couponCode";
import {CartedProduct} from "../../../types/cart";
import {AffiliateCodeVO} from "../../../types/affiliateCode";

enum Step {
  STEP_1 = "STEP_1",
  STEP_2 = "STEP_2",
}

interface Props {
  discountCode: string;
  setDiscountCode: (_: string) => void;
  cartIdxes: number[];
  cartedProducts: CartedProduct[]
  setCode: (params: { type: 'affiliate', code: AffiliateCodeVO } | { type: 'coupon', code: CouponCodeDTO } | null) => void;
}

export function CodeDiscountPanel(
    { discountCode, setDiscountCode, setCode, cartIdxes, cartedProducts }
        : Props) {
  const codeDiscountTitleRef = useRef<HTMLSpanElement>(null);
  const codeInputRef = useRef<HTMLInputElement>(null);
  const [invalidMessage, setInvalidMessage] = useState("");
  const [step, setStep] = useState(Step.STEP_1);
  const {getCouponCode} = useCouponCodeGetter({
    setCodeErrorMessage:setInvalidMessage
  });
  const isCouponCode = discountCode.length >= 10;

  const { getAvailableAffiliateCode } = useAvailableAffiliateCodeGetter({
    setCodeErrorMessage: setInvalidMessage,
  });

  // 코드 적용 버튼 이벤트 함수
  const onClickApplyButton = useCallback(() => {
    isCouponCode ? handleCouponCode() : handleAffiliateCode();
    // eslint-disable-next-line
  }, [isCouponCode, discountCode]);

  const onClickCancelButton = useCallback(() => {
    setCode(null);
    setDiscountCode("");
    setStep(Step.STEP_1);
  }, [setDiscountCode, setCode]);

  const isCompleted = useMemo(() => step === Step.STEP_2, [step]);

  const handleCouponCode = useCallback(() => { // 쿠폰코드 등록
    getCouponCode({
      code: discountCode,
      productIdx: cartedProducts[0].product.idx
    }).then(result => {
      if (result) {
        setCode({type: 'coupon', code: result});
        setStep(Step.STEP_2);
      }
    });
    // eslint-disable-next-line
  }, [discountCode, cartedProducts, setCode]);

  const handleAffiliateCode = useCallback(() => { // 제휴코드 등록
    if (!discountCode.trim()) {
      codeInputRef.current?.focus();
      return;
    }

    setInvalidMessage("");
    getAvailableAffiliateCode(discountCode, cartIdxes)
        .then(result => {
          if (result) {
            setCode({type: 'affiliate', code: result});
            setStep(Step.STEP_2);
          }
        });
    // eslint-disable-next-line
  }, [discountCode, cartIdxes, setCode, codeInputRef]);

  useEffect(() => {
    if (!discountCode) {
      setStep(Step.STEP_1);
      setInvalidMessage("");
    }
  }, [discountCode]);

  return (
    <div className={`${style.supportPanel} ${style.codePanel}`}>
      <div className={style.titleBar}>
        <h3 className={style.sectionTitle}>
          <span ref={codeDiscountTitleRef}>코드 할인</span>
          <SupportButton
            title={"코드 이용 안내"}
            content={["쿠폰과 할인 코드는 중복 적용이 불가능합니다."]}
            supportLeft={-(codeDiscountTitleRef.current?.getBoundingClientRect().width ?? 0)}
          />
        </h3>
      </div>
      <div className={style.inputArea}>
        <input
          ref={codeInputRef}
          type={"text"}
          placeholder={"코드를 입력해 주세요."}
          className={`${style.codeInput} ${discountCode ? style.hasValue : ""} ${invalidMessage ? style.invalid : ""}`}
          value={discountCode}
          disabled={isCompleted}
          onChange={(e) => {
            setDiscountCode(e.target.value.toUpperCase());
            setInvalidMessage("");
          }}
        />
        {step === Step.STEP_1 && (
          <button className={style.applyButton} onClick={onClickApplyButton}>
            적용
          </button>
        )}
        {isCompleted && (
          <button className={style.cancelButton} onClick={onClickCancelButton}>
            취소
          </button>
        )}
        {invalidMessage ? <span className={style.invalidMessage}>{invalidMessage}</span> : <></>}
        {isCompleted ? <span className={style.completedMessage}>코드 할인이 적용되었습니다.</span> : <></>}
        <div className={style.inputWrapper}></div>
      </div>
    </div>
  );
}
