import style from "../../assets/scss/sub/qna.module.scss";
import { useLanguage } from "../../hooks/hooks";
import {QnASenderForm} from "./QnASenderForm";

export function QnA() {
  const { isKorean } = useLanguage();

  return (
    <>
      {isKorean && (
        <div className={style.qnaArea}>
          <span className={style.anyQuestionsText}>문의사항이 있으신가요?</span>
          <QnASenderForm type={'KAKAO'} />
        </div>
      )}
    </>
  );
}
