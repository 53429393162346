import styles from "../../../assets/scss/common/button/buyVoucherBtn.module.scss";
import floatingBtnStyle from "../../../assets/scss/common/button/floatingBtn.module.scss";
import voucherIcon from "../../../assets/images/button/buy_voucher/icon.svg";
import speechBubbleTail from "../../../assets/images/button/buy_voucher/speech_bubble_tail.svg";
import { useNavigate } from "react-router-dom";

export function BuyVoucherBtn() {
  const navigate = useNavigate();
  const onClickVoucher = () => navigate(`/purchase-voucher`);

  return (
    <div className={`${floatingBtnStyle.bottomBtn} ${styles.voucher}`} onClick={onClickVoucher}>
      <div className={floatingBtnStyle.discountBadge}>
          {/* 여름 기한 한정 문구 변경임 */}
          {/*추가혜택 제공!*/}
          {/*여름방학 특가!*/}
          신학기 맞이 할인!
        <img className={floatingBtnStyle.tail} src={speechBubbleTail} alt={"말풍선 꼬리"} />
      </div>
      <div className={`${floatingBtnStyle.leftImage} ${styles.leftImage}`}>
        <img src={voucherIcon} alt={"티켓 아이콘"} />
      </div>
      <span className={floatingBtnStyle.text}>검사이용권 구매</span>
    </div>
  );
}
