import style from "../../assets/scss/sub/topBanner.module.scss";
import smallSettingIcon from "../../assets/images/mypage/small_setting_icon.svg";
import voucher_add_icon from "../../assets/images/mypage/voucher_add_icon.svg";
import docIcon from "../../assets/images/mypage/doc_icon.svg";
import couponIcon from "../../assets/images/mypage/coupon_icon.svg";
import allCouponIcon from "../../assets/images/mypage/all_coupon_icon.svg";
import counselingIcon from "../../assets/images/mypage/couneling_coupon.svg";
import kIcon from "../../assets/images/mypage/small_k_icon.svg";
import dIcon from "../../assets/images/mypage/small_d_icon.svg";
import settingDarkIcon from "../../assets/images/mypage/setting_dark_icon.svg";
import {useEffect} from "react";
import {useUserInfoGetter} from "../common/hooks/useUserInfoGetter";
import {useNavigate} from "react-router-dom";
import {useTopBannerFunctions} from "./hooks/useTopBannerFunctions";
import {useAppDispatch, useModal} from "../../hooks/hooks";

interface TopBannerProps {
  totalVoucherData: {
    organDcasVoucherCnt: number;
    organKpassVoucherCnt: number;
  };
}

export function TopBanner({ totalVoucherData }: TopBannerProps) {
    const { openVoucherRegisterModal } = useModal();

  const { user } = useUserInfoGetter();
  const navigate = useNavigate();
  const { getAllCouponCount, totalCouponCount,
    getProfileList, totalTestCount,
    getCounselingVoucher, totalCounselingVoucherCount
  } = useTopBannerFunctions();

  useEffect(() => {
    getAllCouponCount().then();
    getProfileList().then();
    getCounselingVoucher().then()
  }, [getAllCouponCount, getProfileList, getCounselingVoucher]);

  const onClickVoucherRegister = () => {
    openVoucherRegisterModal();
  };

  return (
      <div className={style.myPageBanner}>
          <div className={style.topTextContainer}>
        <span className={style.greetingText}>
          <span className={style.strong}>{user?.name}</span> 님 안녕하세요 :)
        </span>
              <div className={style.memberInfoWrapper}>
                  <button className={style.memberInfoBtn} onClick={() => onClickVoucherRegister()}>
                      <img src={voucher_add_icon} alt={"voucherAddIcon"}/>
                      이용권 코드 등록
                  </button>
                  <button className={style.memberInfoBtn} onClick={() => navigate(`/mypage/user`)}>
                      <img src={smallSettingIcon} alt={"smallSettingIcon"}/>
                      회원정보
                  </button>
              </div>

              <div className={style.mobileIconWrapper}>
                  <img className={style.mobileVoucherRegisterIcon} src={voucher_add_icon} alt="voucher"
                       onClick={() => onClickVoucherRegister()}/>
                  <img className={style.mobileMemberInfoIcon} src={settingDarkIcon} alt="settings"
                       onClick={() => navigate(`/mypage/user`)}/>
              </div>
          </div>
          <div className={style.bottomTextContainer}>
              <div className={style.countContainer}>
                  <div className={style.docIcon}>
                      <img src={docIcon} className={style.iconImg} alt={"docIcon"}/>
                  </div>
                  <div className={style.textArea}>
                      <div className={style.textTitle1}>누적 검사 횟수</div>
              <div className={style.numberText}>{totalTestCount}회</div>
            </div>
          </div>
          <div className={style.verticalLine}></div>
          <div className={style.countContainer}>
            <div className={style.docIcon}>
              <img src={couponIcon} className={style.iconImg} alt={"couponIcon"}/>
            </div>
            <div className={style.textArea}>
              <div className={style.textTitle2}>보유 쿠폰</div>
              <div className={style.numberText}>{totalCouponCount}장</div>
            </div>
          </div>
          <div className={style.verticalLine}></div>
          <div className={style.countContainer}>
            <div className={style.docIcon}>
              <img src={counselingIcon} className={style.iconImg} alt={"counselingIcon"}/>
            </div>
            <div className={style.textArea}>
              <div className={style.textTitle2}>상담이용권</div>
              <div className={style.numberText}>{totalCounselingVoucherCount}장</div>
            </div>
          </div>
          <div className={style.verticalLine}></div>
          <div className={style.countContainer}>
            <div className={style.docIcon}>
              <img src={allCouponIcon} className={style.iconImg} alt={"allCouponIcon"}/>
            </div>
            <div className={style.textArea}>
              <div className={style.textTitle3}>보유 이용권(소속+검사)</div>
              <div className={`${style.numberText} ${style.mobileNumberText}`}>
                <div className={style.couponContainer}>
                  <img src={kIcon} className={style.kIcon} alt={"kIcon"}/>
                  <span className={style.kPassCount}>
                  {totalVoucherData?.organKpassVoucherCnt ? totalVoucherData?.organKpassVoucherCnt : 0}매
                </span>
                </div>
                <div className={style.couponContainer}>
                  <img src={dIcon} className={style.dIcon} alt={"dIcon"}/>
                  <span className={style.dCasCount}>
                  {totalVoucherData?.organDcasVoucherCnt ? totalVoucherData?.organDcasVoucherCnt : 0}매
                </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}
