import style from "./externalVoucherModal.module.scss";
import React, {useEffect} from "react";

interface Args {
    activeBox: number | null;
    setActiveBox: (_: number | null) => void;
    codeValues: string[];
    setCodeValues: (_: string[]) => void;
    errorText: string;
    setErrorText: (_: string) => void;
    onSubmit: (_: void) => void
}

export function CodeInput(
    {activeBox, setActiveBox, codeValues, setCodeValues, errorText, setErrorText, onSubmit}: Args) {

    useEffect(() => {
        setErrorText("")
    }, [activeBox]);

    // 붙여넣기 함수
    const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>, index: number) => {
        e.preventDefault();

        const pastedText = e.clipboardData.getData('text');
        const cleanedText = pastedText.replace(/[^A-Za-z0-9]/g, '').toUpperCase();
        const startIndex = index;

        const newValues = [...codeValues];
        for (let i = 0; i < cleanedText.length; i++) {
            const targetIndex = startIndex + i;
            if (targetIndex < 8) {
                newValues[targetIndex] = cleanedText[i];
            }
        }

        setCodeValues(newValues);

        const nextIndex = Math.min(startIndex + cleanedText.length, 7);
        setActiveBox(nextIndex);

        const nextInput = document.querySelectorAll('input')[nextIndex];
        if (nextInput) {
            nextInput.focus();
        }
    };

    // 값 입력 함수
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const value = e.target.value;
        if (/^[A-Za-z0-9]$/.test(value)) {
            const newValues = [...codeValues];
            newValues[index] = isNaN(Number(value)) ? value.toUpperCase() : value;
            setCodeValues(newValues);

            if (index < 7) { // 다음 박스로 이동
                setActiveBox(index + 1);

                const nextInput = document.querySelectorAll('input')[index + 1]; // 다음 input에 focus
                if (nextInput) {
                    nextInput.focus();
                }
            }
        }
    }

    // 엔터, 글자 삭제 등 처리 함수
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index:number) => {
        if (e.key === 'Backspace') { // 현재 입력값이 있으면 지우고, 없으면 이전 칸으로
            const newValues = [...codeValues];
            if (newValues[index] === '') { // 이전 칸으로 이동
                if (index > 0) {
                    setActiveBox(index - 1);
                    const prevInput = document.querySelectorAll('input')[index - 1];
                    if (prevInput) {
                        prevInput.focus();
                    }
                }
            } else { // 현재 값 지우기
                newValues[index] = '';
                setCodeValues(newValues);
            }
        } else if (e.key === 'Enter') {
            onSubmit();
        }
    }

    return (
        <div className={style.codeInputWrapper}>
            <div className={style.codeInput}>
                {[...Array(8)].map((_, index) => (
                    <input
                        key={index}
                        type="text"
                        maxLength={1}
                        autoFocus={activeBox === index}
                        className={`${style.codeBox} ${activeBox === index ? style.active : ''}`}
                        value={codeValues[index] || ''}
                        onPaste={(e) => handlePaste(e, index)}
                        onChange={(e) => handleChange(e, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        onFocus={(e) => {
                            setActiveBox(index);
                            const input = e.target;
                            input.setSelectionRange(input.value.length, input.value.length);
                        }}
                    />
                ))}
            </div>

            {errorText !== "" && (
                <span className={style.warningText}>{errorText}</span>
            )}
        </div>
    )
}