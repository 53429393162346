import style from "../../assets/scss/common/header.module.scss";
import loginIcon from "../../assets/images/header/login_icon.svg";
import expandedIcon from "../../assets/images/header/expanded_icon.svg";
import collapsedIcon from "../../assets/images/header/collapsed_icon.svg";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {useAppDispatch, useAppSelector, useAuth, useAwardBanner, useModal, useUserAgent} from "../../hooks/hooks";
import {VoucherCount} from "../../types/voucher";
import {getOrganVoucherCountAsync, getUserVoucherCountAsync} from "../../store/voucher/voucherSlice";
import {CouponCount} from "../../types/coupon";
import {getUserCouponCountAsync, setUserCouponCount, userCouponCountState} from "../../store/coupon/couponSlice";
import {ModalType} from "../../types/common";
import {CouponVoucherStatus} from "./CouponVoucherStatus";
import {useClickEvent} from "../../hooks/useClickEvent";
import {useUserInfoGetter} from "./hooks/useUserInfoGetter";
import {getProfileAuthTokenAsync, getProfilesAsync} from "../../store/profile/profileSlice";

interface Props {
  signOut: VoidFunction;
}
export function LoginBar({ signOut }: Props) {
  const dispatch = useAppDispatch();

  const [userVoucherCount, setUserVoucherCount] = useState(0);
  const [organVoucherCount, setOrganVoucherCount] = useState(0);
  const [organKpassVoucherCount, setOrganKpassVoucherCount] = useState(0);
  const userCouponCount = useAppSelector(userCouponCountState);
  const [isCouponVoucherCollapsed, setIsCouponVoucherCollapsed] = useState(true);

  const { hideAwardBanner, setHideAwardBanner } = useAwardBanner();

  const { isLoggedIn } = useAuth();
  const { openModal } = useModal();
  const { isDesktop, isTablet, isMobile } = useUserAgent();
  const { getUserInfo, user } = useUserInfoGetter();
  const awardBannerDesc = isMobile
      ? 'K-PASS 지능검사에 열정적인 사랑을 보내주신 모든 분들께 감사드리며,앞으로도 우리 아이들을 위해 대한민국 최고의 디지털 두뇌 지능검사 서비스를 제공해 드릴 것을 약속드립니다.'
      : 'K-PASS 지능검사에 열정적인 사랑을 보내주신 모든 분들께 감사드리며,\n앞으로도 우리 아이들을 위해 대한민국 최고의 디지털 두뇌 지능검사 서비스를 제공해 드릴 것을 약속드립니다.'

  const onClickLoginBtn = (e: React.MouseEvent) => {
    openModal(ModalType.LOGIN);
    e.stopPropagation();
  };

  const onClickLogoutBtn = () => signOut();

  // 검사이용권
  const getUserVoucherCount = useCallback(async () => {
    try {
      const { count }: VoucherCount = await dispatch(getUserVoucherCountAsync()).unwrap();
      setUserVoucherCount(count);
    } catch (error) {
      console.error(error);
    }
  }, [dispatch]);

  const getOrganVoucherCount = useCallback(async () => {
    try {
      const { count }: VoucherCount = await dispatch(getOrganVoucherCountAsync()).unwrap();
      setOrganVoucherCount(count);
    } catch (error) {
      console.error(error);
    }
  }, [dispatch]);

  const getCouponCount = useCallback(async () => {
    try {
      const { totalElements }: CouponCount = await dispatch(getUserCouponCountAsync()).unwrap();
      dispatch(setUserCouponCount(totalElements));
    } catch (error) {
      console.error(error);
    }
  }, [dispatch]);

  const getProfileList = useCallback(async () => {
    try {
      // 모든 프로필 정보를 가져옴
      const response = await dispatch(getProfilesAsync()).unwrap();

      let totalKpassQuantity = 0;

      // 모든 프로필을 순회하면서 accessToken을 가져오고, voucher 개수를 계산
      const promises = response.map(async (profile: any) => {
        const myProfileIndex = profile.idx;

        // 각 프로필에 대해 accessToken을 가져옴
        const profileResponse = await dispatch(getProfileAuthTokenAsync({ userProfileIdx: myProfileIndex })).unwrap();

        const accessToken = profileResponse.accessToken;

        // accessToken을 이용해 voucher 정보를 가져옴
        const response2 = await fetch(`${process.env.REACT_APP_BASE_URL}api/v1/dcas/game-content/profile/voucher`, {
          method: "GET",
          headers: {
            Accept: "*/*",
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const data = await response2.json();

        // "organ_manager"와 "kpass"에 해당하는 voucher 정보를 필터링
        const organKpass = data.voucherInformation.find(
            (voucher: any) => voucher.whose === "ORGAN_MANAGER" && voucher.unitVoucherType === "KPASS"
        );

        if (organKpass) {
          totalKpassQuantity += organKpass.quantity;
        }
      });

      // 모든 프로필에 대한 비동기 작업이 완료될 때까지 기다림
      await Promise.all(promises);

      // console.log("총 KPASS 이용권 개수:", totalKpassQuantity);
      setOrganKpassVoucherCount(totalKpassQuantity);
    } catch (error) {
      console.log(error);
    }
  }, [dispatch]);

  useEffect(() => {
    if (isLoggedIn) {
      if (!user) {
        getUserInfo().then();
      }

      getUserVoucherCount().then();
      getOrganVoucherCount().then();
      getProfileList().then();
      getCouponCount().then();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, user]);

  useEffect(() => {}, [hideAwardBanner]);
  useEffect(() => {}, [isTablet, isMobile]);

  // "오늘 하루 그만 보기" 버튼 클릭 시 호출
  const handleNotTodayClick = () => {
    setHideAwardBanner(true);
    const now = new Date();
    const tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
    localStorage.setItem("hideAwardBanner", "true");
    localStorage.setItem("hideBannerExpiry", tomorrow.getTime().toString());
  };

  const LoginBtn = () => (
      <>
        <button className={style.loginBtn} onClick={onClickLoginBtn}>
          <img className={style.icon} src={loginIcon} alt="login-icon" />
          <span className={style.text}>로그인 & 회원가입</span>
        </button>
      </>
  );

  const couponVoucherOptionsRef = useRef(null);
  useClickEvent({ elementRefs: [couponVoucherOptionsRef], doOnClickBg: () => setIsCouponVoucherCollapsed(true) });

  const LoggedInContent = () => (
      <div className={style.loggedInStatus}>
        {isDesktop ? (
            <CouponVoucherStatus
                couponCount={userCouponCount}
                organVoucherCount={organVoucherCount}
                organKpassVoucherCount={organKpassVoucherCount}
                userVoucherCount={userVoucherCount}
            />
        ) : (
            <></>
        )}
        {isDesktop ? (
            <>
          <span className={style.userName}>
            <span className={style.accent}>{user?.name}</span> 님
          </span>
            </>
        ) : (
            <div className={style.myInfoWrapper}>
          <span className={style.userName}>
            <span className={style.accent}>{user?.name}</span> 님
          </span>
              <div className={style.voucherCouponButtonWrapper}>
                <button
                    className={style.voucherCouponButton}
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsCouponVoucherCollapsed(!isCouponVoucherCollapsed);
                    }}
                >
                  <span>쿠폰·이용권</span>
                  <img src={isCouponVoucherCollapsed ? collapsedIcon : expandedIcon} alt={"펼침 아이콘"} />
                </button>
                {isCouponVoucherCollapsed ? (
                    <></>
                ) : (
                    <div ref={couponVoucherOptionsRef}>
                      <CouponVoucherStatus
                          couponCount={userCouponCount}
                          organVoucherCount={organVoucherCount}
                          organKpassVoucherCount={organKpassVoucherCount}
                          userVoucherCount={userVoucherCount}
                      />
                    </div>
                )}
              </div>
            </div>
        )}

        <button className={style.logoutBtn} onClick={onClickLogoutBtn}>
          로그아웃
        </button>
      </div>
  );

  // 브라우저 width값 계산
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
      <>
        <div className={style.loginBar}>
          <div className={style.innerBar}>{isLoggedIn ? <LoggedInContent/> : <LoginBtn/>}</div>
        </div>
        {!hideAwardBanner && (
            <div className={style.brandBannerBox}>
              <div className={style.brandBannerFrame} onClick={handleNotTodayClick}>
                <div className={style.bannerContainer}>
                  <div className={windowWidth <=1960 ? style.backgroundWrapper : style.backgroundUpperWrapper}>
                    <div className={style.brandBackground1}/>
                  </div>
                  {/*  트로피  */}
                  <div className={style.brandTrophyContainer}>
                    <div className={style.brandBackground2}/>
                    <div className={style.brandTrophy}/>
                  </div>

                  {/*  문구  */}
                  <div className={style.brandDescContainer}>
                    <div className={style.brandDescWrapper}>
                      <div className={style.brandDescTitle}>
                        <p className={style.bracket}>[ 2025 소비자가 뽑은 가장 신뢰하는 브랜드 대상 ]</p>
                        <span className={style.bold}>K-PASS 디지털 두뇌 지능검사</span>
                        가{isMobile && '\n'} 선정 되었습니다!

                      </div>
                      <div className={style.brandDescTitle}>
                        {awardBannerDesc}
                      </div>
                    </div>
                  </div>

                  {/*  오늘 하루 그만보기  */}
                  <div className={style.notTodayContainer}>
                    <div className={style.xIcon}/>
                    <div className={style.notTodayText}>
                      오늘 하루 그만 보기
                    </div>
                  </div>
                </div>
              </div>
            </div>
        )}
      </>
  );
}
